.transaction-view {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 800px;
  margin: 20px auto;
  color: #333333;
  line-height: 1.5;
}

.transaction-view h2,
.transaction-view h3 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 1.8em;
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th,
table td {
  padding: 12px;
  border: 1px solid #e0e0e0;
  text-align: left;
  font-size: 1em;
}

table th {
  background-color: #f4f6f8;
  font-weight: 600;
  color: #2c3e50;
}

table td:first-child {
  font-weight: 600;
  color: #2c3e50;
}

table td {
  color: #555555;
}

.status {
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 12px;
  text-align: center;
  display: inline-block;
  font-size: 0.85em;
  text-transform: capitalize;
  width: fit-content;
  white-space: nowrap;
}

.status-inprogress {
  background-color: #f1c40f;
  color: #ffffff;
}

.status-completed {
  background-color: #2ecc71;
  color: #ffffff;
}

.status-cancelled {
  background-color: #e74c3c;
  color: #ffffff;
}

.payment-history {
  margin-top: 20px;
}

.payment-history h3 {
  text-align: center;
  color: #34495e;
  margin-bottom: 16px;
  font-size: 1.4em;
}

/* Collateral Styles */
.collateral-cell {
  display: flex;
  align-items: center; /* Centers items vertically */
  justify-content: flex-start; /* Aligns items to the left */
  gap: 12px; /* Adds spacing between logo and text */
}

.collateral-logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ddd;
}

.collateral-item {
  display: flex;
  align-items: center; /* Ensures the text is vertically aligned with the logo */
  justify-content: center; /* Centers the text horizontally near the logo */
  gap: 8px; /* Adds spacing between multiple items if applicable */
}

.collateral-item span {
  font-size: 1rem;
  color: #333;
  display: flex;
  align-items: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .transaction-view {
    padding: 16px;
    margin: 10px;
  }

  .transaction-view h2,
  .transaction-view h3 {
    font-size: 1.5em;
    margin-bottom: 14px;
  }

  table th,
  table td {
    padding: 10px;
    font-size: 0.9em;
  }

  .status {
    font-size: 0.8em;
    padding: 5px 8px;
  }

  .payment-history h3 {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .transaction-view {
    padding: 12px;
    margin: 8px;
  }

  .transaction-view h2,
  .transaction-view h3 {
    font-size: 1.2em;
    margin-bottom: 12px;
  }

  table th,
  table td {
    padding: 8px;
    font-size: 0.85em;
  }

  .status {
    font-size: 0.75em;
    padding: 4px 6px;
  }

  .payment-history h3 {
    font-size: 1em;
  }
}
