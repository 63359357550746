.web3-container {
  background-color: #ffffff;
  color: #0052ff;
  padding: 5% 10%;
  text-align: center;
}

.web3-header {
  margin-bottom: 5%;
}

.web3-title {
  font-size: 3rem;
  font-weight: bold;
  color: #2b2f32;
  margin-bottom: 1%;
}

.web3-subtitle {
  font-size: 1.25rem;
  color: #a6a6a6;
}

.web3-cards {
  display: flex;
  justify-content: center;
  gap: 2%;
  margin: 4% 0;
}

.web3-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  max-width: 300px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.web3-card h3 {
  font-size: 1.5rem;
  color: #0052ff;
  text-align: center;
  font-weight: bold;
}

.web3-card p {
  color: #000000;
  margin: 10px 0;
  flex-grow: 1;
}

.web3-button {
  background-color: #0052ff;
  color: #ffffff;
  border: 2px solid #0052ff;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-top: 20px; /* Added padding above the button */
}

/* Hover effect */
.web3-button:hover {
  background-color: #003cbf;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 82, 255, 0.3);
}

/* Active/Click effect */
.web3-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(0, 82, 255, 0.2);
}

/* Focus state for accessibility */
.web3-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 82, 255, 0.4);
}

.web3-feature {
  margin-top: 5%;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 10px;
}

.web3-carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.web3-carousel-button {
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
}

.web3-feature-content {
  flex: 1;
  padding: 0 20px;
}

.web3-feature-tag {
  font-size: 0.9rem;
  color: #a6a6a6;
  margin-bottom: 5px;
}

.web3-feature-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.web3-feature-description {
  font-size: 1rem;
  color: #a6a6a6;
  margin-bottom: 20px;
}

.web3-learn-more {
  background-color: #6b4eff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .web3-title {
    font-size: 2rem;
  }

  .web3-subtitle {
    font-size: 1rem;
  }

  .web3-cards {
    flex-direction: column;
  }

  .web3-card {
    margin-bottom: 20px;
  }

  .web3-button {
    font-size: 1rem;
    padding: 6px 12px;
    margin-top: 15px; /* Slightly less margin for mobile */
  }

  .web3-feature {
    padding: 10px;
  }

  .web3-feature-title {
    font-size: 1.5rem;
  }
}
