/* Main container for the news section */
.news-section {
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  /* Heading for the news section */
  .news-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  /* News grid container */
  .news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Individual news card */
  .news-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
  }
  
  /* News card image */
  .news-card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  /* Content inside the news card */
  .news-card-content {
    padding: 15px;
  }
  
  /* News category label */
  .news-category {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  /* News title styling */
  .news-title {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
    color: #333;
  }
  
  /* News source styling */
  .news-source {
    font-size: 14px;
    font-weight: bold;
    color: #007bff;
    margin: 5px 0;
  }
  
  /* Time of the news */
  .news-time {
    font-size: 12px;
    color: #888;
    margin: 5px 0;
  }
  
  /* Link to full news article */
  .news-link {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
    margin-top: 10px;
    display: inline-block;
  }
  
  .news-link:hover {
    text-decoration: underline;
  }
  