.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 12px 24px;
    z-index: 1000;
    text-align: center;
    max-width: 300px;
    width: 90%;
  }
  
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .popup p {
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .popup-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .popup-link:hover {
    text-decoration: underline;
  }
  
  .popup-close-btn {
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .popup-close-btn:hover {
    background-color: #d32f2f;
  }
  