/* User Transactions Container */
.user-transactions {
  margin: 0 auto;
  max-width: 1200px;
  padding: 2rem;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 20px;
  transition: all 0.3s ease;
}

/* Title */
.user-transactions h2 {
  font-size: 2rem;
  color: #0d6efd; /* Changed to requested color */
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
  letter-spacing: 0.02em;
}

/* Filter Radio Buttons */
.filter-container {
  margin-bottom: 1.5rem;
  display: flex;
  gap: 1.5rem;
  padding: 1rem;
  background: #f7fafc;
  border-radius: 12px;
}

.filter-container label {
  font-size: 1rem;
  color: #4a5568;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.filter-container label:hover {
  background-color: #edf2f7;
}

.filter-container input[type="radio"] {
  width: 1.25rem;
  height: 1.25rem;
  accent-color: #2b6cb0;
  cursor: pointer;
}

/* Transaction Table */
.transaction-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.95rem;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.transaction-table th {
  background: #edf2f7;
  color: #2d3748;
  font-weight: 600;
  text-align: left;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  border-bottom: 2px solid #e2e8f0;
  transition: all 0.2s ease;
}

.transaction-table th:hover {
  background: #e2e8f0;
}

.transaction-table td {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #edf2f7;
  color: #4a5568;
  vertical-align: middle;
}

.transaction-table tr {
  transition: all 0.2s ease;
}

.transaction-table tr:hover {
  background: #f7fafc;
  transform: scale(1.01);
}

/* Collateral Styling */
.collateral-cell {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  align-items: center;
}

.collateral-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f1f5f9;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
}

.collateral-logo {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e2e8f0;
  transition: transform 0.2s ease;
}

.collateral-item:hover .collateral-logo {
  transform: scale(1.1);
}

/* Participant Styling */
.participant-cell {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.participant-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e2e8f0;
  transition: transform 0.2s ease;
}

.participant-cell:hover .participant-avatar {
  transform: scale(1.05);
}

/* Status Styling */
.status {
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-align: center;
  display: inline-block;
  font-size: 0.875rem;
  text-transform: capitalize;
  min-width: 100px;
  transition: all 0.2s ease;
}

.status:hover {
  transform: translateY(-2px);
}

.status-in-progress {
  background: #f6ad55;
  color: #fff;
}

.status-completed {
  background: #38a169;
  color: #fff;
}

.status-cancelled {
  background: #e53e3e;
  color: #fff;
}

.status-waiting-lend {
  background: #ecc94b;
  color: #1a202c;
}

.status-waiting-borrow {
  background: #4299e1;
  color: #fff;
}

/* No Transactions */
.no-transactions {
  text-align: center;
  padding: 3rem;
  font-size: 1.5rem;
  color: #718096;
  background: #fff;
  border-radius: 12px;
}

/* Get Started Button */
.get-started-button {
  margin-top: 1.5rem;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  color: #fff;
  background: linear-gradient(135deg, #2b6cb0, #4299e1);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.get-started-button:hover {
  background: linear-gradient(135deg, #2c5282, #2b6cb0);
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .user-transactions {
    max-width: 95%;
    padding: 1.5rem;
  }

  .transaction-table {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    gap: 1rem;
  }

  .transaction-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .transaction-table th,
  .transaction-table td {
    padding: 0.75rem;
  }
}

@media (max-width: 480px) {
  .user-transactions {
    padding: 1rem;
  }

  .user-transactions h2 {
    font-size: 1.5rem;
  }

  .transaction-table th,
  .transaction-table td {
    font-size: 0.8rem;
    padding: 0.5rem;
  }

  .status {
    padding: 0.4rem 0.8rem;
    font-size: 0.75rem;
    min-width: 80px;
  }

  .collateral-logo {
    width: 24px;
    height: 24px;
  }

  .participant-avatar {
    width: 28px;
    height: 28px;
  }
}
