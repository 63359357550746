.user-payment-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
}

.payment-header {
  margin-bottom: 20px;
}

.payment-header h2 {
  font-size: 24px;
  color: #2c3e50;
  margin: 0;
  font-weight: 600;
}

.payment-content {
  padding: 0 10px;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
}

.no-payment {
  text-align: center;
  padding: 20px;
  color: #666;
}

.no-payment p {
  margin-bottom: 15px;
  font-size: 16px;
}

.payment-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 6px;
  background-color: #fafafa;
  margin-bottom: 15px;
}

.payment-info p {
  margin: 8px 0;
  font-size: 15px;
  color: #333;
}

.payment-info strong {
  color: #2c3e50;
}

.payment-form {
  padding: 15px;
  background-color: #fafafa;
  border-radius: 6px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #2c3e50;
}

.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.form-group input:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 5px rgba(13, 110, 253, 0.2);
}

.action-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-btn,
.submit-btn {
  background-color: #0d6efd;
  color: white;
}

.add-btn:hover,
.submit-btn:hover {
  background-color: #0b5ed7;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
}

.delete-btn:hover {
  background-color: #c82333;
}

.cancel-btn {
  background-color: #6c757d;
  color: white;
}

.cancel-btn:hover {
  background-color: #5c636a;
}

.form-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
}

@media (max-width: 600px) {
  .user-payment-container {
    margin: 10px;
    padding: 15px;
  }

  .payment-detail {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .form-actions {
    flex-direction: column;
    gap: 8px;
  }
}
