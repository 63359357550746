/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
  background-color: #f5f6f8;
  color: #1a1d2d;
  line-height: 1.5;
  min-height: 100vh;
}

/* Wrapper */
.user-account-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header */
.account-header {
  padding: 20px 0;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 30px;
}

.greeting {
  font-size: 32px;
  font-weight: 700;
  color: #1f2937;
  text-align: left;
}

/* Main Container */
.account-container {
  padding: 0 10px;
}

.account-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Card Styles */
.account-details-card,
.account-payment-card,
.account-transactions-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.02);
  transition: transform 0.2s ease-in-out;
}

.account-details-card:hover,
.account-payment-card:hover,
.account-transactions-card:hover {
  transform: translateY(-2px);
}

/* Specific Section Styling */
.account-details-card {
  grid-column: span 1;
}

.account-payment-card {
  grid-column: span 1;
}

.account-transactions-card {
  grid-column: span 2;
}

/* Loading State */
.loading-state {
  text-align: center;
  padding: 40px;
  color: #6b7280;
  font-size: 16px;
  background: #ffffff;
  border-radius: 12px;
}

/* Button Styles */
button {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  background-color: #3b82f6;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}

button:hover {
  background-color: #2563eb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button:active {
  transform: translateY(1px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .account-grid {
    grid-template-columns: 1fr;
  }

  .account-transactions-card {
    grid-column: span 1;
  }

  .greeting {
    font-size: 24px;
  }

  .account-details-card,
  .account-payment-card,
  .account-transactions-card {
    padding: 20px;
  }
}
