/* Body font */
@font-face {
  font-family: 'MulishLight';
  src: url('./fonts/Mulish-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* Global body font */
body {
  font-family: 'MulishLight', sans-serif !important;
}

