.footer {
  background-color: #222;
  padding: 2rem 1rem;
  font-size: 14px;
  text-align: center;
  color: white; /* Makes all footer text white */
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.footer-section {
  flex: 1 1 200px;
  max-width: 250px;
}

.footer-title {
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  cursor: pointer;
  margin-bottom: 0.5rem;
  color: white; /* Ensures footer links are white */
}

.footer-links li:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-icons img {
  width: 24px;
  height: 24px;
 
}

.footer-bottom {
  margin-top: 2rem;
  font-size: 12px;
  color: white; /* Ensures footer-bottom text is white */
}
