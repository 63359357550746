.marketplace-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* Adds padding around the container */
}

.card-wrapper {
  background-color: #f9f9f9;
  width: 100%;
  max-width: 1500px; /* Sets a consistent maximum width */
  margin: 20px 0; /* Adds space between cards */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
  border-radius: 8px; /* Optional: Adds rounded corners */
}

@media (max-width: 768px) {
  .card-wrapper {
    max-width: 90%; /* Adjusts card width for smaller screens */
  }
}
