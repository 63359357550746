.mainHeading {
  font-size: 32px;
  line-height: 48px;
  color: #756859;
  font-weight: 600;
}

.btnSignUp:hover {
  background-color: #222 !important;
}

.additional-padding {
  margin: 8% 0;
  padding-bottom: 20px; /* Adjust the padding as needed */
}

.text-secondary {
  color: #6c757d;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.mt-2 {
  margin-top: 0.5rem;
}

.pt-5 {
  padding-top: 3rem;
}

.pb-5 {
  padding-bottom: 3rem;
}

.rounded-pill {
  border-radius: 50rem;
}

.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 30px;
  font-weight: 600;
}

h5 {
  font-size: 20px;
  line-height: 30px;
}

h4 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
}

i {
  font-style: italic;
}
