/* Arena.css - Optimized and organized */

/* Font imports */
@font-face {
  font-family: "MullishBold";
  src: url("../../fonts/Mulish-ExtraBold.ttf") format("truetype");
}

/* Variables for consistent styling */
:root {
  --primary-color: #0052ff;
  --primary-hover: #003ecb;
  --text-dark: #1f2937;
  --text-medium: #374151;
  --text-light: #6b7280;
  --border-color: #e5e7eb;
  --bg-color: #ffffff;
  --shadow-sm: 0 4px 12px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 6px 16px rgba(0, 0, 0, 0.1);
  --radius-sm: 6px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --container-width: 1200px;
  --card-height: 280px;
  --card-width: 250px;
}

/* Layout */
.arena-container {
  max-width: var(--container-width);
  padding: 20px;
  margin: 0 auto;
}

.arena-header {
  margin-bottom: 24px;
}

.arena-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 15px;
}

/* Filters */
.filter-section {
  background: var(--bg-color);
  padding: 16px;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}

.type-filter {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.type-button {
  flex: 1;
  padding: 10px 16px;
  border: 2px solid var(--primary-color);
  border-radius: var(--radius-md);
  background: var(--bg-color);
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.type-button.active {
  background: var(--primary-color);
  color: var(--bg-color);
}

.type-button:hover:not(.active) {
  background: rgba(0, 82, 255, 0.1);
}

.advanced-filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.filter-label {
  font-size: 0.9rem;
  color: var(--text-medium);
}

.bold-label {
  font-weight: 800;
  font-family: "MullishBold", sans-serif;
  color: var(--text-medium);
  font-size: 0.95rem;
}

.filter-group select {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  background: var(--bg-color);
  color: var(--text-medium);
  font-size: 0.95rem;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-group select:hover {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 82, 255, 0.1);
}

.filter-group select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(0, 82, 255, 0.2);
}

/* Deal Cards */
.deal-card {
  background: var(--bg-color);
  padding: 16px;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: var(--card-height);
  font-size: 0.9rem;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.deal-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.deal-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  overflow: hidden;
}

.deal-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collateral-row {
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-weight: 600;
  color: var(--text-light);
  font-size: 0.9rem;
}

.value {
  font-weight: 500;
  color: var(--text-dark);
  font-size: 0.9rem;
}

.collateral-container {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 4px;
  max-height: 75px;
  overflow-y: auto;
  width: 100%;
}

.collateral-item {
  display: flex;
  align-items: center;
  gap: 3px;
  background: var(--bg-color);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.75rem;
  min-width: 0;
  border: 1px solid #f0f0f0;
}

.collateral-logo {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}

.collateral-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50px;
}

.action-button {
  display: block;
  text-align: center;
  padding: 10px;
  margin-top: 12px;
  background: var(--primary-color);
  color: var(--bg-color);
  text-decoration: none;
  border-radius: var(--radius-md);
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: lowercase;
  transition: background 0.3s ease, transform 0.2s ease;
}

.action-button:hover {
  background: var(--primary-hover);
  transform: scale(1.02);
}

/* Skeleton Loading */
.skeleton-card {
  background: var(--bg-color);
  padding: 16px;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: var(--card-height);
}

.skeleton-item {
  width: 100%;
}

.skeleton-text {
  height: 14px;
  width: 80%;
  background: #f0f0f0;
  border-radius: 4px;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

/* Error State */
.error-container {
  max-width: 100%;
  padding: 20px;
  margin: 20px auto;
  text-align: center;
  background-color: var(--bg-color);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
}

.error-container p {
  color: #dc2626;
  margin-bottom: 15px;
  font-weight: 500;
}

.retry-button {
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: var(--bg-color);
  border: none;
  border-radius: var(--radius-md);
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.retry-button:hover:not(:disabled) {
  background-color: var(--primary-hover);
  transform: scale(1.02);
}

.retry-button:disabled {
  background-color: #d1d5db;
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .arena-container {
    padding: 12px;
  }

  .type-button {
    padding: 8px 12px;
    font-size: 0.9rem;
  }

  .advanced-filters {
    grid-template-columns: 1fr;
  }

  .arena-content {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .deal-card {
    height: 260px;
  }

  .skeleton-card {
    height: 260px;
  }
}

@media (max-width: 480px) {
  .arena-content {
    grid-template-columns: 1fr;
  }

  .deal-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .deal-row .label,
  .deal-row .value {
    font-size: 0.85rem;
  }

  .action-button {
    padding: 8px;
    font-size: 0.85rem;
  }
}
