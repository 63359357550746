.news-container {
    padding: 20px;
    background-color: #f9f9f9; /* Optional: light background */
    border-radius: 8px; /* Optional: rounded corners */
  }
  
  .news-card-section {
    margin-bottom: 20px; /* Space below CryptoNewsCard */
  }
  
  .news-section {
    /* Optional: Add specific styles for the NewsSection if needed */
  }
  