.borrow-request {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.borrow-request label {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.borrow-request input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}

.borrow-request input[type="range"] {
  cursor: pointer;
}

/* Collateral Section */
.collateral-section {
  margin-top: 15px;
}

.custom-dropdown {
  position: relative;
  padding: 18px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.selected-option {
  color: #000;
  font-size: 14px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-option:hover {
  background-color: #f1f1f1;
}

.crypto-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.collateral-details p {
  font-size: 14px;
  color: #000;
  margin-top: 10px;
}
