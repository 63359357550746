.payment-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.payment-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 480px;
}

.payment-title {
  color: #2c3e50;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}

.connect-button {
  width: 100%;
  padding: 12px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease;
}

.connect-button:hover {
  background: #2980b9;
}

.wallet-details {
  padding: 20px 0;
}

.account-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #34495e;
}

.label {
  font-weight: 500;
}

.value {
  color: #7f8c8d;
}

.transfer-form {
  margin-top: 20px;
}

.form-field {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  color: #2c3e50;
  font-weight: 500;
}

.form-input,
.form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  background: #fff;
  transition: border-color 0.3s ease;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #3498db;
}

.destination-info {
  color: #7f8c8d;
  font-size: 14px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 6px;
}

.transfer-button {
  width: 100%;
  padding: 12px;
  background: #2ecc71;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease;
}

.transfer-button:hover {
  background: #27ae60;
}

.error-text {
  color: #e74c3c;
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
}

.success-text {
  color: #2ecc71;
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
}

.tx-link {
  color: #3498db;
  text-decoration: none;
}

.tx-link:hover {
  text-decoration: underline;
}
