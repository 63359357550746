/* Main Page Layout */
.request-page {
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;
}

@font-face {
  font-family: "MullishBold";
  src: url("../../fonts/Mulish-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.bold-text {
  font-family: "MullishBold", sans-serif !important;
  font-weight: normal;
}

/* Page Title */
.page-title {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}

/* Mode Selector */
.mode-selector {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  margin-bottom: 30px;
}

.mode-option {
  padding: 8px 16px;
  font-size: 1rem;
  border: 1px solid #0052ff;
  border-radius: 8px;
  background-color: #fff;
  color: #0052ff;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  flex: 1 1 45%;
}

.mode-option.active {
  border-color: #0052ff;
  background-color: #0052ff;
  color: #fff;
}

.mode-option:hover {
  background-color: #e6f0ff;
  color: #0052ff;
}

/* Form Layout */
.form-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 15px;
}

.form-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

input,
select,
textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* Information Box */
.info-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.info-box {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  min-width: 300px;
  max-width: 350px;
}

/* Sticky Button */
.next-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 0;
}

.next-button {
  padding: 10px 20px;
  background-color: #0052ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
}

.next-button:hover {
  background-color: #003bb5;
}

/* Responsive Adjustments */
@media (max-width: 576px) {
  .next-button-container {
    justify-content: center; /* Center button on small screens */
  }
}
