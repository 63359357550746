.user-details-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

/* Header Section */
.user-details-header {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #f3f4f6;
}

.user-photo-container {
  margin-right: 20px;
}

.profile-photo {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #f3f4f6;
}

.user-info-primary {
  flex: 1;
}

.user-username {
  font-size: 20px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 4px;
}

.user-email {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 8px;
}

.kyc-status-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.kyc-label {
  font-size: 14px;
  color: #4b5563;
}

.kyc-status {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 999px;
  font-size: 13px;
  font-weight: 500;
}

.kyc-completed {
  background-color: #ecfdf5;
  color: #047857;
}

.kyc-check {
  margin-right: 4px;
  font-size: 12px;
}

.kyc-button {
  padding: 6px 12px;
  background-color: #3b82f6;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.kyc-button:hover {
  background-color: #2563eb;
}

/* Stats Section */
.user-stats-container {
  padding-top: 20px;
}

.stats-summary {
  margin-bottom: 20px;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.stat-label {
  font-size: 14px;
  color: #6b7280;
}

.stat-value {
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
}

/* Pie Chart */
.transaction-visualization {
  display: flex;
  align-items: center;
  gap: 24px;
}

.pie-chart-wrapper {
  position: relative;
}

.pie-chart {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
}

.pie-chart-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50%;
}

.pie-chart-legend {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

.legend-color {
  width: 12px;
  height: 12px;
  border-radius: 3px;
}

.legend-color.lend {
  background-color: #f59e0b;
}

.legend-color.borrow {
  background-color: #10b981;
}

.legend-label {
  font-size: 14px;
  color: #6b7280;
}

.legend-value {
  font-size: 14px;
  font-weight: 500;
  color: #1f2937;
  margin-left: 4px;
}

/* Responsive Design */
@media (max-width: 480px) {
  .user-details-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .transaction-visualization {
    flex-direction: column;
    align-items: flex-start;
  }
}
