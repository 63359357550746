/* General Credita Features Styles */
.credita-container {
  padding: 60px 20px 20px;
  border-radius: 12px;
  max-width: 1200px;
  margin: auto;
}

/* Add styles for the new heading */
.feature-heading {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 40px; /* Increased margin for more space below the heading */
}

/* Styles for the new h3 (Start Borrowing Now / Start Lending Now) */
.credita-content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0052ff;
  margin-bottom: 10px;
  text-align: center;
}

/* Styles for the new description paragraph */
.credita-content p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Add styles for the new heading */
.feature-heading {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 40px; /* Increased margin for more space below the heading */
}

.radio-inputs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  background-color: #eeeeee;
  border-radius: 8px;
  padding: 8px;
}

.radio {
  flex: 1;
  text-align: center;
}

.radio input {
  display: none;
}

.radio .name {
  display: block;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  background-color: transparent;
  transition: all 0.3s ease;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
}

.radio input:checked + .name {
  background-color: #0052ff;
  color: #ffffff;
  font-weight: 600;
}

.credita-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.investment-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.borrow-options,
.lend-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Button Styling */
.button {
  background-color: #0052ff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #0041cc;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.button:active {
  background-color: #0031a3;
  transform: scale(0.98);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .credita-container {
    padding: 40px 10px 20px;
  }

  .feature-heading {
    font-size: 1.6rem; /* Adjust font size for smaller screens */
    margin-bottom: 30px; /* Reduced space below heading */
  }

  .radio-inputs {
    flex-direction: column;
    gap: 10px;
  }

  .radio .name {
    font-size: 0.9rem;
    padding: 8px;
  }

  .credita-content {
    flex-direction: column; /* Stack content for better mobile display */
    height: auto;
    padding: 20px 0;
  }

  .investment-options,
  .borrow-options,
  .lend-options {
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .credita-container {
    padding: 30px 10px 20px; /* Even smaller padding for small screens */
  }

  .feature-heading {
    font-size: 1.4rem; /* Smaller heading size for small screens */
    margin-bottom: 20px;
  }

  .radio .name {
    font-size: 0.8rem;
    padding: 6px;
  }

  .button {
    font-size: 0.9rem;
    padding: 8px 16px; /* Slightly smaller button for smaller screens */
  }

  .credita-content {
    padding: 15px 0;
    height: auto; /* Ensure flexibility for smaller screens */
  }

  .investment-options,
  .borrow-options,
  .lend-options {
    gap: 10px;
  }
}
