/* General body styling */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f6f9;
  color: #333;
}

/* Container styling */
.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  max-width: 500px;
}

/* Header */
.payment-title {
  color: #0056b3;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.payment-amount {
  margin-bottom: 20px;
  font-size: 18px;
  color: #444;
  text-align: center;
}

/* Drop-in container */
.dropin-container {
  width: 100%;
  margin-top: 10px;
}

/* Error message */
.error-message {
  color: red;
  text-align: center;
  font-size: 16px;
}
