.how-it-works-container {
  padding: 5% 10%;
  background-color: #f9f9f9;
  text-align: center;
}

.step-image {
  width: 100%;
  max-width: 150px;
  margin-bottom: 10px;
  border-radius: 10px; /* Add rounded corners */
  transition: transform 0.3s; /* Optional: Add a hover effect */
}

.step-image:hover {
  transform: scale(1.05); /* Optional: Slightly zoom on hover */
}

.how-it-works-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2%;
}

.how-it-works-steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2%;
}

.step {
  text-align: center;
  flex: 1;
  max-width: 200px;
}

.step-image {
  width: 100%;
  max-width: 150px;
  margin-bottom: 10px;
}

.step-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333333;
}

.how-it-works-description {
  margin-top: 5%;
  font-size: 1rem;
  color: #555555;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
