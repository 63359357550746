/* Main Container */
.arena-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px; /* Matches the padding in MarketPlace */

    min-height: 100vh;
  }

/* Adjust space between logo and text */
.arena-details-table td img.collateral-logo {
    margin-right: 8px; /* Adds space between the logo and the text */
  }
  
  
  /* Card Wrapper */
  .card-wrapper {
    background-color: #f9f9f9;
    width: 100%;
    max-width: 1500px; /* Matches the maximum width in MarketPlace */
    margin: 20px 0; /* Adds consistent spacing between cards */
    padding: 30px; /* Additional padding inside the card */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds shadow */
    border-radius: 8px; /* Rounded corners */
  }

 
  
  .collateral-logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
  }
  
  
  /* Title Styling */
  .arena-title {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
  }
  
  /* Table Styling */
  .arena-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .arena-details-table th,
  .arena-details-table td {
    text-align: left;
    padding: 16px;
    font-size: 16px;
    color: #555;
    border-bottom: 1px solid #eaeaea;
  }
  
  .arena-details-table th {
    font-weight: bold;
    text-transform: uppercase;
  }
  

  
  /* Action Section */
  .action-container {
    margin-top: 30px;
    padding: 20px;
    font-size: 16px;
    text-align: center;
  }
  
  /* Pay Button */
  .pay-button {
    display: inline-block;
    margin-top: 15px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #0d6efd;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .pay-button:hover {
    background-color: #357abd;
    transform: translateY(-2px);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .card-wrapper {
      max-width: 90%; /* Adjusts card width for smaller screens */
      padding: 20px;
    }
  
    .arena-title {
      font-size: 24px;
    }
  
    .arena-details-table th,
    .arena-details-table td {
      font-size: 14px;
      padding: 12px;
    }
  
    .pay-button {
      padding: 10px 20px;
      font-size: 14px;
    }
  }
  