.join-community-container {
  padding: 5% 10%;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.join-community-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1%;
}

.clickable {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.clickable:hover {
  transform: scale(1.05);
}

.join-community-description {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 3%;
}

.join-community-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px; /* Increased gap for better spacing */
}

.community-card {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 280px;
  height: auto;
  margin: 15px; /* Added margin to further increase spacing */
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* Smaller logo container */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.community-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.community-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.community-description {
  font-size: 0.9rem;
  color: #777777;
}

/* Newsletter Section */
.newsletter-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.newsletter-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 1rem;
}

.newsletter-button {
  background-color: #0052ff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.newsletter-button:hover {
  background-color: #003bbd;
}

.newsletter-privacy {
  font-size: 0.8rem;
  color: #777777;
  margin-top: 10px;
}

.privacy-link {
  color: #0052ff;
  text-decoration: none;
}

.privacy-link:hover {
  text-decoration: underline;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .join-community-cards {
    flex-direction: column;
    align-items: center;
    gap: 25px; /* Slightly reduced for mobile */
  }

  .community-card {
    width: 90%;
    max-width: 300px;
  }
}
