/* General Crypto Prices Styles */
.crypto-container {
  padding: 60px 20px 20px;
  border-radius: 12px;
  max-width: 1200px;
  margin: auto;
}

.crypto-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.crypto-header h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.crypto-navigation {
  display: flex;
  gap: 10px;
}

.crypto-arrow {
  background: #0052ff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-size: 1.2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.crypto-arrow:disabled {
  background: #f5f5f5;
  color: #b0b0b0;
  cursor: not-allowed;
  box-shadow: none;
}

.crypto-arrow:hover:not(:disabled) {
  background: #0041cc;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.crypto-arrow:active:not(:disabled) {
  background: #0031a3;
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Grid Layout */
.crypto-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
  padding-top: 20px;
}

.crypto-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
}

.crypto-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.crypto-card img {
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
  border-radius: 50%;
  background-color: #f9f9f9;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.crypto-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crypto-name {
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.crypto-price {
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.crypto-change {
  font-size: 0.7rem;
  font-weight: 500;
}

/* Skeleton Loading Styles */
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.skeleton-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 8px;
}

.skeleton-text {
  width: 80%;
  height: 10px;
  margin: 5px auto;
}

@keyframes skeleton-loading {
  0% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

/* Error State Styles */
.error-container {
  max-width: 1200px;
  padding: 60px 20px 20px;
  margin: auto;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-container p {
  color: #721c24;
  margin-bottom: 15px;
}

.retry-button {
  padding: 8px 16px;
  background-color: #0052ff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.retry-button:hover:not(:disabled) {
  background-color: #003ecb;
}

.retry-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .crypto-header h2 {
    font-size: 1.4rem;
  }

  .crypto-navigation {
    gap: 8px;
  }

  .crypto-arrow {
    width: 36px;
    height: 36px;
    font-size: 1rem;
  }

  .crypto-grid {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 12px;
  }

  .crypto-card {
    padding: 8px;
  }

  .crypto-card img,
  .skeleton-image {
    width: 28px;
    height: 28px;
  }

  .crypto-name,
  .crypto-price {
    font-size: 0.75rem;
  }

  .crypto-change {
    font-size: 0.7rem;
  }

  .skeleton-text {
    height: 8px;
  }
}

@media (max-width: 480px) {
  .crypto-container,
  .error-container {
    padding: 20px 10px;
  }

  .crypto-header h2 {
    font-size: 1.2rem;
  }

  .crypto-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 10px;
  }

  .crypto-arrow {
    width: 28px;
    height: 28px;
    font-size: 0.9rem;
  }

  .crypto-card img,
  .skeleton-image {
    width: 24px;
    height: 24px;
  }

  .crypto-name,
  .crypto-price {
    font-size: 0.65rem;
  }

  .crypto-change {
    font-size: 0.6rem;
  }

  .skeleton-text {
    height: 6px;
  }
}
