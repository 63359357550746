/* General Page Styling */
.about-us {
  max-width: 1100px;
  margin: 0 auto;
  padding: 60px 25px;
  text-align: center;
  border-radius: 10px;
}

/* Headings */
.main-heading h2 {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.4;
  color: #222;
  margin-bottom: 30px;
}

.main-heading h4 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #444;
  margin-bottom: 25px;
}

.heading-border {
  max-width: 90%; /* Responsive width */
  height: 2px;
  background-color: #eee;
  margin: 0 auto 30px;
}

/* About Content */
.about-content {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px;
  background: white;
  border-radius: 8px;
}

.text-column {
  flex: 1;
  min-width: 320px;
  text-align: left;
  line-height: 1.8;
}

.text-column p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.text-column a {
  color: #0077cc;
  text-decoration: none;
}

.text-column a:hover {
  text-decoration: underline;
}

/* People Section */
.people-heading {
  font-size: 2rem;
  font-weight: 700;
  margin: 50px 0 30px;
  color: #222;
}

/* People Cards */
.people-content {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

.person-card {
  text-align: center;
  max-width: 340px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
}

.person-card:hover {
  transform: translateY(-5px);
}

.profile-pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 1.5px solid #0077cc;
}

.person-role {
  font-size: 1rem;
  color: #666;
  margin: 5px 0;
}

.person-bio {
  font-size: 0.95rem;
  color: #444;
  line-height: 1.6;
  margin-top: 15px;
}

/* Social Icons */
.social-links {
  display: flex;
  justify-content: center;
  gap: 18px;
  margin-top: 12px;
}

.social-icon {
  width: 22px;
  height: 22px;
  transition: transform 0.2s ease-in-out, opacity 0.2s;
  opacity: 0.85;
}

.social-icon:hover {
  transform: scale(1.15);
  opacity: 1;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .about-us {
    padding: 40px 15px; /* Reduce padding for smaller screens */
  }

  .about-content {
    flex-direction: column;
    padding: 20px;
    gap: 30px; /* Reduce space for better flow */
  }

  .text-column {
    text-align: center;
    min-width: auto;
  }

  .heading-border {
    max-width: 80%; /* Prevents overflow on mobile */
  }

  .people-content {
    flex-direction: column;
    align-items: center;
    gap: 30px; /* Reduce spacing for better mobile fit */
  }

  .person-card {
    max-width: 100%;
    padding: 20px;
  }
}
