/* TopBar Styling */
@font-face {
  font-family: "MullishBold";
  src: url("../../fonts/Mulish-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Body font */
.topbar {
  font-family: "MullishBold", sans-serif !important;
}

.profile-container {
  position: relative;
  display: inline-block;
}

.profile-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-dropdown {
  position: absolute;
  right: 0;
  top: 35px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  width: 120px;
}

.profile-dropdown div {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
}

.profile-dropdown div:hover {
  background-color: #f5f5f5;
}

.topbar {
  padding: 0.2rem 1rem; /* Minimal padding for a compact look */
  background-color: #e9f1ff82 !important;
  border-bottom: 1px solid #4fa3e0;
  z-index: 1020; /* Ensure the navbar is above other elements */
  position: sticky;
  top: 0;
  min-height: 45px; /* Explicit minimal height */
}

.topbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Exclude logo from custom font */
.topbar .navbar-logo {
  font-family: inherit; /* Inherit the default body font */
  height: 20px; /* Smaller logo for compactness */
}

.topbar .navbar-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem; /* Reduced gap between links */
}

.topbar .nav-link {
  font-size: 14px; /* Smaller font size */
  margin: 0 0.5rem; /* Reduced margin for tighter spacing */
  font-weight: bold; /* Bold text */
}

.topbar .nav-link.active {
  text-decoration: underline;
}

.topbar .profile-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .topbar {
    padding: 0.2rem 0.8rem; /* Adjusted padding for smaller screens */
  }

  .topbar .nav-link {
    font-size: 12px; /* Smaller font size for mobile */
    margin: 0 0.4rem;
  }

  .topbar .navbar-logo {
    height: 18px;
  }
}

@media (max-width: 576px) {
  .topbar .nav-link {
    font-size: 11px; /* Even smaller font for very small screens */
    margin: 0 0.3rem;
  }

  .topbar .navbar-logo {
    height: 16px;
  }
}

/* Adjustments for Larger Screens */
@media (min-width: 769px) {
  .topbar .container {
    align-items: center;
    min-height: 45px; /* Ensure compact height on larger screens */
  }

  .topbar .nav-link {
    font-size: 14px; /* Consistent font size */
    margin: 0 0.5rem;
  }

  .topbar .navbar-logo {
    height: 20px;
  }
}
