/* Container styling */
.crypto-news-card {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    position: relative;
    background-color: #f9f9f9;
    padding: 10px 0;
  }
  
  /* Scrolling container */
  .scrolling-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  
  /* Scrolling content animation */
  .scrolling-content {
    display: flex;
    align-items: center;
    animation: scroll-left 100s linear infinite;
  }
  
  .scrolling-container:hover .scrolling-content {
    animation-play-state: paused;
  }
  
  /* Each card styling */
  .news-card {
    display: inline-flex;
    align-items: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 8px;
    padding: 6px;
    min-width: 120px; /* Reduced width */
    transition: transform 0.2s ease;
  }
  
  .news-card:hover {
    transform: scale(1.05);
  }
  
  /* Coin logo */
  .coin-logo {
    width: 30px; /* Reduced size */
    height: 30px;
    margin-right: 8px;
  }
  
  /* Coin details */
  .coin-details {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .coin-name {
    font-size: 12px; /* Smaller font */
    font-weight: bold;
    margin: 0;
  }
  
  .coin-price {
    font-size: 10px; /* Smaller font */
    color: #555;
    margin: 2px 0;
  }
  
  .coin-change {
    font-size: 10px; /* Smaller font */
    font-weight: bold;
  }
  
  /* Animation keyframes */
  @keyframes scroll-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  