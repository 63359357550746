/* KiteLogin.css */

/* Page Wrapper */
.kite-login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fa;
  padding: 20px;
}

/* Login Container */
.kite-login-container {
  width: 400px; /* Increased width */
  padding: 40px; /* Adjusted padding */
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center; /* Center all text inside */
}

/* Logo Section */
.kite-login-logo-container {
  margin-bottom: 30px;
}

.kite-login-logo {
  width: 70px; /* Larger logo size */
  height: 70px;
  object-fit: cover; /* Ensures sharp and proportional logo */
}

/* Title Section */
.kite-login-title {
  font-size: 1.7rem; /* Slightly larger font size */
  font-weight: 600;
  color: #333333; /* Dark gray for better contrast */
  margin-bottom: 30px;
}

/* Auth Buttons Container */
.login-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 15px; /* Consistent spacing between buttons */
}

/* Auth Buttons */
.auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #333333; /* Text color */
  background-color: #ffffff; /* Button background */
  border: 1px solid #dcdcdc; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  gap: 10px; /* Space between icon and text */
  cursor: pointer; /* Hand cursor on hover */
  transition: all 0.3s ease; /* Smooth hover effect */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.auth-button:hover {
  background-color: #f8f9fa; /* Slightly darker background on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Stronger shadow on hover */
}

.auth-button svg {
  height: 24px; /* Icon size */
}

/* Privacy Text */
.privacy-text {
  margin-top: 20px;
  font-size: 0.9rem; /* Slightly smaller font size */
  color: #6c757d; /* Light gray text */
  line-height: 1.5;
}

/* Add this rule to ensure the Apple logo is the same size as the Google logo */
.apple-logo {
  height: 24px; /* Same size as the Google logo */
  width: auto;  /* Maintain aspect ratio */
}


.privacy-link {
  color: #007bff; /* Blue color for the link */
  text-decoration: none; /* No underline by default */
}

.privacy-link:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .kite-login-container {
    width: 90%; /* Take up more space on smaller screens */
    padding: 30px; /* Reduce padding */
  }

  .kite-login-logo {
    width: 50px; /* Smaller logo for smaller screens */
  }

  .auth-button {
    font-size: 0.9rem; /* Adjust button font size for smaller screens */
  }

  .privacy-text {
    font-size: 0.85rem; /* Smaller font size for privacy text */
  }
}
