.faq-container {
  padding: 5% 8%;
  max-width: 900px;
  margin: 0 auto;
  text-align: left; /* Ensure container text is aligned to the left */
}

.faq-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center; /* Title stays centered for aesthetics */
  margin-bottom: 1%;
  color: #333333;
}

.faq-subtitle {
  font-size: 1rem;
  text-align: center; /* Subtitle stays centered for aesthetics */
  margin-bottom: 2%;
  color: #666666;
}

.faq-list {
  margin-top: 20px;
}

.faq-item {
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: left; /* Aligns all text in the FAQ item to the left */
}

.faq-question {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Ensure the question starts from the left */
  text-align: left; /* Explicitly align text to the left */
}

.faq-toggle-icon {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #0073e6;
}

.faq-answer {
  margin-top: 10px;
  font-size: 1rem;
  color: #555555;
  line-height: 1.6;
  text-align: left; /* Ensure answer text is aligned to the left */
}

.open .faq-question {
  color: #0073e6;
}

.open .faq-answer {
  display: block;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .faq-container {
    padding: 5%;
    max-width: 95%;
  }

  .faq-title {
    font-size: 2rem;
  }

  .faq-subtitle {
    font-size: 0.95rem;
  }

  .faq-item {
    padding: 12px;
  }

  .faq-question {
    font-size: 1.1rem;
  }

  .faq-answer {
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .faq-container {
    padding: 4%;
    max-width: 100%;
  }

  .faq-title {
    font-size: 1.8rem;
  }

  .faq-subtitle {
    font-size: 0.9rem;
  }

  .faq-item {
    padding: 10px;
  }

  .faq-question {
    font-size: 1rem;
  }

  .faq-answer {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .faq-title {
    font-size: 1.5rem;
  }

  .faq-subtitle {
    font-size: 0.85rem;
  }

  .faq-question {
    font-size: 0.95rem;
  }

  .faq-answer {
    font-size: 0.85rem;
  }

  .faq-toggle-icon {
    font-size: 1.2rem;
  }
}
