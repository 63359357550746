.lend-request {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.lend-request label {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.lend-request input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}

.lend-request input[type="range"] {
  cursor: pointer;
}

.collateral-options {
  margin-top: 15px;
}

.collateral-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.collateral-item label {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px; /* Space between the checkbox and label text */
}

/* Custom checkbox styling */
.collateral-item input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #0052ff; /* Border color set to blue */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.collateral-item input[type="checkbox"]:checked {
  background-color: #0052ff; /* Blue when checked */
  border-color: #0052ff;
}

.collateral-item input[type="checkbox"]:hover {
  border-color: #003bb5; /* Darker blue border on hover */
}

.collateral-item input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 82, 255, 0.3); /* Focus ring */
}

.collateral-item input[type="checkbox"]:checked::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin: 3px auto;
  background-color: #fff; /* Checkmark color */
  border-radius: 50%; /* Rounded checkmark */
}
